@import "./setup";

.OptionsContainer {
  flex-basis: 80%;
  overflow-y: scroll;
}
.PickButtonContainer {
  display: flex;
  align-items: center;
  flex-basis: 20%;
}
.AddOption {
  text-align: center;
  margin: 0 auto;
}

.PickButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80vw;
  margin: 0 auto;
  height: 3em;
  border-radius: 2em;
  font-size: 2em;
  font-weight: bolder;
}

.TitleInput {
    margin: 1em auto 0 auto;
    height: 3em;
    font-size: 2.5em;
    width: 75vw;
    border-radius: .6em;
    text-align: center;
}