@import "./setup";
.Upload {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  flex-direction: column;
}

.uploadInput {
  display: flex;
  flex-direction: row-reverse;
}

.uploadButton {
   display: flex;
   flex-direction: column;
   justify-content: center;
   text-align: center;
}
