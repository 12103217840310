.modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
}

.title {
    margin-top: 1em;
}

.modal_content {
    position: relative;
    width: 80vw;
    background-color: #fff;
    border-radius: 1em;
}
.closeX {
    position: absolute;
    top: 5px;
    right: 10px;
}