@import "./setup";

.OptionsContainer {
  flex-basis: 80%;
  overflow-y: scroll;
}
.PickButtonContainer {
  display: flex;
  align-items: center;
  flex-basis: 20%;
}
.resetButton {
  position: absolute;
  top: calc($HeaderHeight + 1em);
  right: 1em;
  border-radius: .5em;
}
.AddOption {
  text-align: center;
  margin: 0 auto;
}

.PickButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80vw;
  margin: 0 auto;
  height: 3em;
  border-radius: 2em;
  font-size: 2em;
  font-weight: bolder;
}
