@import '../setup.scss';
.pill {
    width: 75%;
    margin: 0 auto;
}
.pillContainer {
    background-color: $main-bg;
    color: $dark-text;
    margin: 1em auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 1em;
    border-radius: 3em;
    font-size: 12px;
}

.pill:hover a, .pill:hover div{
    background-color: $darker-bg;
}

.PillTitle {
    flex-basis: 70%;
    padding-left: .5em;
}
.PillIcons {
    display: flex;
    flex-direction: row;
    flex-basis: 30%;
    justify-content: space-around;
}

// .PillWithIcon div {
//     justify-content: flex-end;
// }
// .PillWithIcon div h1 {
//     justify-content: space-between;
// }