@import "./setup";

.thumbnails {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
}
.thumbnails img {
  width: 30%;
  margin-bottom: 1em;
}