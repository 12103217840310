@import "./setup";

.mainFooter {
  position: fixed;
  bottom: 0px;
  background-color: $main-bg;
  color: $dark-text;
  width: 100%;
  height: $FooterHeight;
}
.mainFooterItems {
  display: flex;
  justify-content: space-evenly;
  height: 100%;
}
.mainFooterItem {
  display: inline-block;
  align-self: center;
  cursor: pointer;
}
.mainFooterItem:hover,
.mainFooterItem:active {
  text-decoration: underline;
}
