/*******************
   ** Media Queries **
   *******************/

$mq-screen: "screen";
$mq-landscape: "screen and (orientation: landscape) and (max-width: 768px)"; // landscape
$mq-small: "screen and (min-width: 375px)"; // mobile
$mq-medium: "screen and (min-width: 768px)"; //tablet
$mq-large: "screen and (min-width: 1024px)"; // large tablet
$mq-xlarge: "screen and (min-width: 1440px)"; //desktop
$mq-largest: "screen and (min-width: 1920px)"; // large desktop

// Variables
$white: #fff;
$black: #000;
$Pica: "IM Fell DW Pica", serif;
$Pica-SC: "IM Fell DW Pica SC", serif;
$Iceland: "Iceland", sans-sarif;
$HeaderHeight: 40px;
$FooterHeight: 40px;
$main-bg: whitesmoke;
$darker-bg: rgb(206, 206, 206);
$dark-text: #333333;
$light-text: whitesmoke;
$full-height: -webkit-fill-available;

body {
  font-size: 16px;
  color: $light-text;
  background-color: $black;
  font-family: $Pica;
  margin-top: $HeaderHeight;
  height: 100%;

  img {
    max-width: 100%;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: $black;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $white;
    border-radius: 6px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(223, 222, 222);
  }
}

body {
  font-size: 3vw;

  @media #{$mq-medium} {
    font-size: 2vw;
  }

  @media #{$mq-large} {
    font-size: 1.8vw;
  }

  @media #{$mq-xlarge} {
    font-size: 1em;
  }
}
.main {
  height: calc(85vh - $HeaderHeight - $FooterHeight - 1em);
  padding-top: 1em;
}
.main > div {
  display: flex;
  flex-direction: column;
  height: 100%;
}

h1 {
  font-size: 3.5em;

  @media #{$mq-medium} {
    font-size: 5.3em;
  }

  @media #{$mq-large} {
    font-size: 3.25em;
  }

  @media #{$mq-xlarge} {
    font-size: 4.5em;
  }
}

h2 {
  font-size: 3.5em;

  @media #{$mq-medium} {
    font-size: 5.3em;
  }

  @media #{$mq-large} {
    font-size: 3.25em;
  }

  @media #{$mq-xlarge} {
    font-size: 4.5em;
  }
}

h4 {
  font-size: 2em;

  @media #{$mq-medium} {
    font-size: 4.3em;
  }

  @media #{$mq-large} {
    font-size: 2.75em;
  }

  @media #{$mq-xlarge} {
    font-size: 3.5em;
  }
}

h5 {
  font-size: 2em;
  text-decoration: underline;

  @media #{$mq-medium} {
    font-size: 5.3em;
  }

  @media #{$mq-large} {
    font-size: 3.25em;
  }

  @media #{$mq-xlarge} {
    font-size: 4.5em;
  }
}

p {
  font-size: 1.42em;
  font-family: $Pica;
}

.title {
  margin: 0 auto;
  text-align: center;
}
.deleteIcon {
  position: absolute;
  top: calc($HeaderHeight + 0.3em);
  right: 0.5em;
}
.bottom_center {
  width: 100%;
  font-size: 3em;
  position: absolute;
  bottom: calc($FooterHeight + 1em);
  text-align: center;
}